
#messages {
  max-width: $breakpoint-sm;
  height: calc(100vh - 1.6 * var(--space-xxxxl)); // experimentally defined
  padding: var(--space-xxs);

  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--space-xs);

  background-color: var(--neutral-layer-3-background);
}

.message {
  width: 75%;
  border: 1px solid var(--color-neutral-400);
  border-radius: var(--radius);
}

#new_message {
  position: absolute;
  height: var(--space-xxl);
  bottom: 0;
  width: 100%;
  max-width: $breakpoint-sm;

  background: var(--color-white);
  margin: 0;

  .material-icons {
    font-size: 200%;
  }
}

.message--text {
  padding: 1em;
}

.message--inbound {
  align-self: start;
  background-color: var(--color-primary-base);
  border-bottom-left-radius: var(--radius-xs);
}

.message--outbound {
  align-self: end;
  background-color: var(--neutral-layer-1-background);
  border-bottom-right-radius: var(--radius-xs);
}

.message--failed {
  opacity: 0.9;
  border: 2px solid var(--color-danger-base);
}
