:root {
  --card-border: var(--border-width-sm) solid var(--color-neutral-light);
}

%card {
  position: relative;
  background-color: var(--color-white);
  border: var(--card-border);
  border-radius: var(--radius);
  box-shadow: var(--shadow-neutral-elevation-md);
  color: var(--color-neutral-darker);
  font-size: var(--text-md);
  line-height: var(--line-height-base);
  padding: var(--space-md);
}

.card {
  @extend %card;
}

.card--no-padding {
  @extend %card;
  padding: 0;
}

.card-body--split {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(auto, 1fr);

  @media (max-width: $breakpoint-sm), (max-height: $breakpoint-sm) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;
  }
}

.card-header {
  display: grid;
  grid-auto-flow: column;
  gap: var(--space-xxs);
  align-items: center;
  justify-content: flex-start;
  background-color: var(--color-neutral-lighter);
  border-bottom: var(--card-border);
  color: var(--color-primary-800);
  font-size: var(--text-lg);
  padding: var(--space-sm) var(--space-md);
  border-top-left-radius: var(--radius);
  border-top-right-radius: var(--radius);
}

.card-heading {
  font-size: var(--text-lg);
  font-weight: 700;
  margin: 0;
}

.card-main {
  display: grid;
  gap: var(--space-md);
  padding: var(--space-md);
}

.card-section:not(:first-of-type) {
  border-left: var(--card-border);

  @media (max-width: $breakpoint-sm), (max-height: $breakpoint-sm) {
    border-left: none;
    border-top: var(--card-border);
  }
}

.card-footer {
  display: grid;
  grid-auto-flow: column;
  gap: var(--space-sm);
  align-items: center;
  justify-content: space-between;
  border-top: var(--card-border);
  padding: var(--space-md);

  @media (max-width: $breakpoint-xs) {
    grid-auto-flow: row;
    grid-template-columns: 1fr;

    .btn {
      width: 100%;
    }
  }
}

.info-message {
  background-color: var(--color-white);
  border: var(--border-width-sm) solid transparent;
  border-radius: var(--radius-sm);
  padding: var(--space-xxs);
  padding-left: calc(2 * var(--space-xs) + 24px);
  padding-right: calc(var(--space-xs) + var(--space-xxs));
  position: relative;
  width: 100%;
  z-index: 1;

  border-color: var(--color-neutral-500);
  color: var(--color-info-text);

  &::before {
    color: var(--color-neutral-500);
    content: 'info';
    position: absolute;
    left: var(--space-xs);
    top: var(--space-xs);
    font-family: var(--font-icons);
    font-size: var(--flash-icon);
    line-height: var(--line-height-tightest);
  }
}
