.checkbox-custom,
.radio-custom,
.radio {
  line-height: var(--line-height-tight);
  display: flex;
  align-items: center;
  font-size: var(--text-md);
}

.form__row {
  display: flex;
  flex-direction: row;
  gap: var(--space-xxs);
  width: 100%;
  align-self: flex-start;
}

@media only screen and (max-device-width: $breakpoint-sm) {
  .form__row {
    flex-direction: column;
  }
}

.form__group {
  display: grid;
  gap: var(--space-xxs);
  width: 100%;
  align-self: flex-start;

  &.radio_buttons,
  &.check_boxes {
    gap: var(--space-xs);
  }
}

.form__label {
  cursor: pointer;
  display: block;
  font-size: var(--text-md);
  color: var(--color-neutral-darker);
}

.checkbox-custom {
  .form__label {
    display: inline;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
  }
}

input[type=radio],
input[type=checkbox] {
  appearance: none;
  border: none;
  content: none;
  margin: 0;
  outline: none;
  position: relative;
  width: auto;
  height: auto;

  &:checked {
    appearance: none;
    border: none;
    content: none;
    outline: none;
    padding: 0;
  }

  &::before {
    display: block;
    margin-right: var(--space-xxs);
    background-color: var(--color-white);
    border: var(--border-width) solid var(--color-neutral-base);
    width: var(--space-md);
    height: var(--space-md);
  }
}

input[type=radio] {
  &::before,
  &::after {
    content: '';
    border-radius: var(--radius-max);
  }
  &:checked::before {
    border-color: var(--color-primary-darker);
  }
  &:checked::after {
    background-color: var(--color-primary-darker);
    position: absolute;
    top: calc(var(--space-xxs) * 0.5);
    left: calc(var(--space-xxs) * 0.5);
    width: var(--space-xs);
    height: var(--space-xs);
  }
}

input[type=checkbox] {
  &::before,
  &::after {
    content: '';
    border-radius: var(--radius-xs);
  }
  &:checked::before {
    border-color: var(--color-primary-darker);
  }
  &:checked::after {
    position: absolute;
    top: 3px;
    left: 7px;
    width: calc(var(--space-xs) * 0.5);
    height: var(--space-xs);
    border-color: var(--color-primary-darker);
    border-style: solid;
    border-width: 0 var(--border-width) var(--border-width) 0;
    border-radius: 0;
    transform: rotate(35deg);
  }
}

// This needs to also affect tom select
select,
trix-editor,
.ts-input,
textarea,
input[type=text],
input[type=email],
input[type=tel],
input[type=password],
input[type=url],
input[type=phone],
input[type=number],
input[type=date],
input[type=datetime-local] {
  appearance: none;
  border-radius: var(--radius-sm);
  border: none;
  border: var(--border-width-sm) solid var(--color-neutral-base);
  background-color: var(--color-white);
  color: var(--color-neutral-darker);
  font-size: var(--text-md);
  font-weight: 400;
  height: var(--input-height);
  padding-left: var(--space-xs);
  padding-right: var(--space-xs);
  width: 100%;

  @media (max-width: $breakpoint-sm) {
    height: var(--mobile-tap-height);
  }

  &:focus-within {
    border: var(--border-width-sm) solid var(--color-neutral-dark);
    outline: 0;
  }

  &:disabled, &[readonly] {
    background-color: var(--color-neutral-lighter);
    pointer-events: none;
  }
}

trix-editor {
  height: auto;
}

textarea {
  padding: var(--space-xs);
  height: calc(3 * var(--input-height));
}

select,
.ts-input {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI5IDE0IiB3aWR0aD0iMjkiPjxwYXRoIGZpbGw9IiNkMWQxZDEiIGQ9Ik05LjM3NzI3IDMuNjI1bDUuMDgxNTQgNi45MzUyM0wxOS41NDAzNiAzLjYyNSIvPjwvc3ZnPgo=');
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 30px 15px;
  padding-right: var(--space-lg);
}

// Make sure this catches normal dropdowns like on the contracts/new page.
input {
  &:disabled {
    + label,
    &::before {
      // Hacky, but no good way to beat the specificity game
      color: var(--color-disabled) !important;
      cursor: not-allowed !important;
    }
  }
}

.form__input--error {
  input:not([type=radio]):not([type=checkbox]), select, textarea, trix-editor {
    box-shadow: var(--color-error-border);
  }
}

.form__error {
  color: var(--color-danger-dark);
  font-size: var(--text-md);
  padding: 0 var(--space-xs);

  &::before {
    font-family: var(--font-icons);
    content: 'error';
    margin-right: var(--space-xxs);
    float: left;
  }
}

.input__warning {
  margin-top: var(--space-xxs);
  color: var(--color-warning-text);
  background-color: var(--color-white);
  border: var(--border-width-sm) solid var(--color-orange-500);
  border-radius: var(--radius-sm);
  padding: var(--space-xxs);
  padding-left: calc(2 * var(--space-xs) + 24px);
  padding-right: calc(var(--space-xs) + var(--space-xxs));
  position: relative;

  &::before {
    color: var(--color-orange-500);
    content: 'warning';
    position: absolute;
    left: var(--space-sm);
    top: calc(50% - var(--text-lg) / 2);
    font-family: var(--font-icons);
    font-size: var(--text-lg);
    line-height: var(--line-height-tightest);
  }

  span {
    font-size: var(--text-sm);
    line-height: var(--line-height-loose);
    margin: 0;
    padding-top: 1px;
  }
}

.form-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: var(--space-md);
}

.form-row--auto {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: var(--space-md);
}

// from https://codyhouse.co/ds/components/app/input-group
.input-group {
  display: flex;

  > * + *:not(:last-child) {
    border-radius: 0;
  }

  > *:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > *:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > *:focus {
    position: relative;
    z-index: 1;
  }

  > *:first-child:not(.input-group__tag) {
    border-right: none;
  }

  > *:last-child:not(.input-group__tag) {
    border-left: none;
  }
}

.input-group__tag {
  display: inline-block; // fallback
  display: flex;
  align-items: center;
  padding-left: var(--space-xxs);
  padding-right: var(--space-xxs);
  background-color: var(--color-neutral-light);
  border-radius: var(--radius-sm);
  white-space: nowrap;
  color: var(--color-neutral-darker);
  border: var(--border-width-sm) solid var(--color-neutral-base);
}

.diagram-pad {
  display: flex;
  flex-direction: column;

  @media screen and (max-device-width: $breakpoint-md) and (orientation: landscape) {
    flex-direction: row;
  }
}

.diagram__canvas {
  width: 100%;
  height: 30rem;
  max-height: 65vh;
  border: 1px solid var(--color-neutral-400);

  @media screen and (max-device-width: $breakpoint-md) and (orientation: landscape) {
    width: calc(100% - 5rem);
  }
}

.diagram-pad__controls {
  margin-top: var(--space-sm);
  display: flex;
  flex-direction: row;
  gap: var(--space-md);
  justify-content: flex-end;

  @media screen and (max-device-width: $breakpoint-md) and (orientation: landscape) {
    margin-left: var(--space-sm);
    flex-direction: column;
    justify-content: center;
  }
}

.form-serial-number-wrapper {
  display: flex;
  gap: var(--space-sm);
  flex-wrap: wrap;
  justify-content: end;
}
