:root {
  --column-width: 950px;
  --container-padding: var(--space-lg);

  @media (max-width: $breakpoint-sm) {
    --container-padding: var(--space-sm);
  }
}

%container {
  display: grid;
  gap: var(--space-md);
  max-width: calc(var(--column-width) + (2 * var(--container-padding)));
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
}

section {
  margin-top: var(--space-xl);
  padding-bottom: var(--space-xxxl);

  @media (max-width: $breakpoint-sm) {
    &.container--table {
      margin-top: 0;
    }
  }
}

.container {
  @extend %container;
}

.container--table {
  @extend %container;

  @media (max-width: $breakpoint-sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.container--page-header {
  @extend .container;

  display: grid;
  grid-auto-flow: row;
  gap: var(--space-xs);

  @media only screen and (max-width: $breakpoint-md) {
    overflow-x: visible !important;
  }
}

.container-flashes {
  display: flex;
  flex-direction: column;
  gap: var(--space-md);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: var(--flash-width);
  overflow-y: scroll;
  padding: var(--space-lg);
  pointer-events: none;
  width: max-content;
  z-index: 2;

  @media (max-width: $breakpoint-xs) {
    align-items: center;
    left: 0;
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: $breakpoint-sm), (max-height: $breakpoint-sm) {
    margin-top: var(--mobile-tap-height);
  }
}

.container-form {
  display: grid;
  gap: var(--space-md);
}

