// Look up icons at: https://material.io/resources/icons/
/* stylelint-disable scss/load-partial-extension -- Need to specify for webpack */
@import 'material-icons/iconfont/material-icons.scss';

.material-icons {
  display: inline-flex;
  font-size: var(--icon-base-material);
  line-height: 0.9em;
  max-width: var(--icon-max-width);
}

.custom-icons {
  display: inline-block;
  width: var(--icon-base-custom);
  height: var(--icon-base-custom);
}

.custom-icons svg {
  width: 100%;
  height: 100%;
}

.sort_link {
  white-space: nowrap;

  .material-icons {
    position: relative;
    top: var(--radius-xs);
  }
}
