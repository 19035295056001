.lead-form__logo {
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translate(0);

  aspect-ratio: 4/3;

  background-color: var(--color-white);

  img {
    max-width: 8rem;
  }
}
