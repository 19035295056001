.toggle {
  border: 1px solid var(--color-primary-200);
  border-radius: var(--radius);
  padding: var(--space-xs);
}

.toggle__option {
  padding: var(--space-xxs) var(--space-sm);
}

.toggle__option--active {
  background: var(--color-primary-200);
}
