@import 'tom-select/dist/scss/tom-select';

.ts-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  min-height: var(--input-height);
  border-radius: var(--radius-sm);
  border: var(--border-width-sm) solid var(--color-neutral-base);

  // Override Tom-Select Clear button to center it to our input height and size it properly!
  .clear-button {
    font-size: var(--text-xl);
    top: var(--text-md);
    right: var(--space-md);
  }
}

.ts-wrapper {
  &.single,
  &.multi,
  &.single.input-active,
  &.multi.input-active {
    .ts-control {
      // Highly specific SVG data image for the dropdown arrow.
      background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMTQiIHdpZHRoPSIyOSI+PHBhdGggZmlsbD0iI2QxZDFkMSIgZD0iTTkuMzc3IDMuNjI1bDUuMDgyIDYuOTM1IDUuMDgxLTYuOTM1Ii8+PC9zdmc+");
      background-position-x: 100%;
      background-position-y: center;
      background-repeat: no-repeat;
    }
  }
}
