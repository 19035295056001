@import url('https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap');

*, ::after, ::before {
  box-sizing: border-box;
}

html {
  height: 100%;

  font-family: var(--font-primary);
  font-size: 16px;
}

body {
  color: var(--neutral-layer-app-text);
  background: var(--neutral-layer-app-background);

  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

body.role--staff {
  --color-highlight: var(--color-primary-600);
  --color-highlight-text: var(--color-primary-100);
}

body.role--dealer {
  --color-highlight: var(--color-primary-300);
  --color-highlight-text: var(--color-primary-900);
}

body.role--manufacturer {
  --color-highlight: var(--color-secondary-500);
  --color-highlight-text: var(--color-neutral-900);
}

body.role--driver {
  --color-highlight: var(--color-neutral-500);
  --color-highlight-text: var(--color-neutral-900);
}

button, input, optgroup, select, textarea {
  margin: 0;

  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, figure, pre {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: var(--space-sm);

  font-weight: 400;
}

h1 {
  font-size: var(--text-xl);
}

h2 {
  font-size: var(--text-xl);
}

h3 {
  font-size: var(--text-lg);
}

h4 {
  color: var(--neutral-layer-2-text);

  font-size: var(--text-md);
}

h5 {
  font-size: var(--text-sm);
}

h6 {
  font-size: var(--text-xs);
}

p {
  margin-bottom: var(--space-sm);

  font-size: 1rem;
}

small {
  font-size: var(--text-sm);
}

a {
  color: var(--color-primary-dark);
  text-decoration: underline;
}

.wrapper--main {
  width: 100%;
  overflow: auto;
  --sidebar-width: 0px; /* stylelint-disable-line length-zero-no-unit */
  border-top: var(--space-xxs) solid var(--color-highlight);
}

.modal__body > .wrapper--main {
  border-top: 0;
}

.sidebar ~ .wrapper--main {
  --sidebar-width: 275px;
}

.responsive-two-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media only screen and (max-device-width: $breakpoint-md) {
  .responsive-two-column {
    flex-direction: column-reverse;
  }

  .wrapper-main {
    border-top: 0;
  }
}

.subtitle {
  font-size: var(--text-base);
  font-style: normal;
  font-weight: 500;
  line-height: 1.2rem;
  letter-spacing: 0;
}

.list--compact {
  width: 70%;
}

@media only screen and (max-device-width: $breakpoint-sm) {
  .list--compact {
    width: 100%;
  }
}

.li--unnumbered {
  list-style-type: none;
}

// taken from https://stackoverflow.com/a/34310815/2100919
[data-letters]::before {
  content: attr(data-letters);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  padding: 0;
}

.avatar {
  min-width: 2.75em;
  height: 2.75em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
