.styleguide-section__title {
  margin-bottom: var(--space-lg);
  padding-bottom: var(--space-xs);

  border-bottom: var(--border-width) solid var(--neutral-layer-1-border);

  &::before {
    margin-right: var(--space-xs);

    content: '#';

    color: var(--neutral-layer-1-text);
  }
}

.styleguide-section--typography {
  flex: 1;
}

.styleguide-section--spacing {
  flex: 4;
}

.spacing-example {
  position: relative;

  display: block;

  height: 2rem;
  margin-bottom: var(--space-lg);

  background: var(--color-primary-base);
}

.section--icons {
  h4 {
    text-transform: none;
    font-weight: bold;
  }

  .code {
    font-family: monospace;
    background: var(--color-neutral-50);
    border-radius: var(--radius-sm);
    word-wrap: break-word;
    padding: 0.4rem 0.3rem;
  }
}
