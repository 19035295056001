// Page headers that can expand on mobile
.accordion--page-header {

  %accordion__header {
    gap: var(--space-xxs);
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    color: var(--color-neutral-dark);
    cursor: pointer;
    font-size: var(--text-md);
  }

  .accordion__header {
    display: none;
  }

  .accordion__header--open {
    @extend %accordion__header;
    display: none;
  }

  .accordion__header--closed {
    @extend %accordion__header;
    display: none;
  }

  .accordion__content {
    display: block;
  }
}

@media (max-width: $breakpoint-sm), (max-height: $breakpoint-sm) {

  .accordion--page-header {

    .accordion__header--closed {
      display: grid;
    }

    .accordion__content {
      display: none;
    }
  }

  .accordion--open {

    .accordion__content--open {
      display: block;
    }

    .accordion__header--open {
      padding-top: var(--space-xs);
      display: grid;
    }

    .accordion__header--closed {
      display: none;
    }
  }
}

// Normal accordion
.accordion {
  border: 1px solid var(--color-neutral-200);
  border-radius: var(--radius);

  .accordion__header {
    background: var(--color-neutral-light);
    border-radius: var(--radius-md);
    cursor: pointer;
    padding: var(--space-md) var(--space-lg);

    font-size: var(--text-md);
    font-weight: 400;
    color: var(--color-neutral-600);

    h3, h5 {
      margin-bottom: 0;
    }

    .material-icons {
      color: var(--color-neutral-600);
      width: 24px;
      height: 24px;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .status {
      span {
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }
  }

  .accordion__header--open {
    display: none;
  }

  .accordion__header--closed {
    display: flex;
  }

  &.accordion--open {

    .accordion__header {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .accordion__header--open {
      display: flex;
    }

    .accordion__header--closed {
      display: none;
    }
  }

  // Old code
  .accordion-group__header {
    opacity: 0.6;
    font-size: var(--text-md);
    font-weight: 500;
  }

  .accordion__content {
    padding: 0;
    display: none;

    margin: var(--space-lg);

    .subtitle {
      padding: var(--space-lg);
    }
  }

  // TODO add transitions here
  .accordion__content--open {
    display: block;
  }

  @media screen and (max-width: $breakpoint-sm) {
    .accordion__content {
      margin: var(--space-md) var(--space-sm);
    }
  }
}
