.identity {
  background-color: var(--color-primary-lightest);
  display: flex;
  flex-direction: row;
  text-align: left;
  justify-content: center;
  align-items: center;
  padding: var(--space-md);
  border: var(--border-width-sm) solid var(--color-neutral-50);
  border-radius: var(--radius-sm);
  width: 100%;
  transition: var(--transition-fast);

  @media (max-width: $breakpoint-xs) {
    padding: var(--space-sm);
  }

  &:hover {
    background-color: var(--color-neutral-300);
  }

  &:focus {
    background-color: var(--color-neutral-500);
    box-shadow: 0 0 0 3px var(--color-neutral-300);
  }
}

.identity__avatar {
  background-color: var(--color-highlight);
  color: var(--color-highlight-text);
}

.identity__info {
  display: flex;
  flex-direction: column;
}

.identity__name {
  color: var(--color-black);
}

.identity__title {
  color: var(--color-neutral-700);
  margin-top: 2px;
}

.identity__wrapper {
  position: relative;
}

.identity__tray {
  background-color: var(--color-primary-lightest);
  border: var(--border-width-sm) solid var(--color-neutral-50);
  border-radius: var(--radius-lg);
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: var(--space-sm);

  font-size: var(--text-lg);
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translateY(0);
  z-index: -1;

  transition: opacity 150ms;

  a {
    text-decoration: none;
  }
}

// TODO add transitions here
.identity__tray--open {
  opacity: 1;
  z-index: 1;
  height: auto;
  overflow: visible;
  transform: translateY(-100%) translateY(-10px);
}

.identity__switch {
  display: block;
  color: inherit;
  padding: var(--space-xs);

  &:hover {
    background-color: var(--sidebar-hover-color);
  }

  &:active {
    background-color: var(--color-neutral-500);
  }
}

.identity__switch--disabled {
  color: var(--color-neutral-600);

  &:hover {
    background-color: var(--sidebar-hover-color);
  }
}

.identity__separator {
  border-color: var(--color-neutral-700);
  width: 70%;
  margin: var(--space-xs) auto;
}

.identity__item {
  padding: var(--space-xs);
  border-radius: var(--radius);
  color: var(--color-primary-dark);

  &:hover {
    background-color: var(--sidebar-hover-color);
  }

  &:active {
    background: var(--color-neutral-500);
  }
}

.sign-out {
  color: var(--color-danger-dark);
  width: 100%;
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
