/* stylelint-disable declaration-colon-space-after */

// Breakpoints - SCSS variables until support is available for custom properties
$breakpoint-xs: 32rem; // ~512px phone
$breakpoint-sm: 48rem; // ~768px vertical ipad
$breakpoint-md: 64rem; // ~1024px landscape ipad
$breakpoint-lg: 80rem; // ~1280px small laptop
$breakpoint-xl: 90rem; // ~1440px big laptop

:root {

  // Initial color values
  --color-white: hsl(0, 100%, 100%);
  --color-black: hsl(0, 0%, 0%);

  --color-neutral-h: 217;
  --color-neutral-s: 15%;
  --color-neutral-l: 76%;

  --color-primary-h: 203;
  --color-primary-s: 66%;
  --color-primary-l: 65%;

  --color-secondary-h: 52;
  --color-secondary-s: 58%;
  --color-secondary-l: 69%;

  --color-green-h: 140;
  --color-green-s: 49%;
  --color-green-l: 54%;

  --color-orange-h: 33;
  --color-orange-s: 92%;
  --color-orange-l: 47%;

  --color-red-h: 3;
  --color-red-s: 100%;
  --color-red-l: 55%;

  // Numbered color weight scales (similar to font weights)
  --color-neutral-50:  hsl(var(--color-neutral-h), var(--color-neutral-s), 99%); // Lightest
  --color-neutral-100: hsl(var(--color-neutral-h), var(--color-neutral-s), 98%); // Lighter
  --color-neutral-200: hsl(var(--color-neutral-h), var(--color-neutral-s), 93%); // Light
  --color-neutral-300: hsl(var(--color-neutral-h), var(--color-neutral-s), 89%);
  --color-neutral-400: hsl(var(--color-neutral-h), var(--color-neutral-s), 76%); // Base
  --color-neutral-500: hsl(var(--color-neutral-h), var(--color-neutral-s), 66%);
  --color-neutral-600: hsl(var(--color-neutral-h), var(--color-neutral-s), 56%);
  --color-neutral-700: hsl(var(--color-neutral-h), var(--color-neutral-s), 46%); // Dark
  --color-neutral-800: hsl(var(--color-neutral-h), var(--color-neutral-s), 26%); // Darker
  --color-neutral-900: hsl(var(--color-neutral-h), var(--color-neutral-s), 16%); // Darkest

  --color-primary-50:  hsl(var(--color-primary-h), var(--color-primary-s), 99%); // Lightest
  --color-primary-100: hsl(var(--color-primary-h), var(--color-primary-s), 95%); // Lighter
  --color-primary-200: hsl(var(--color-primary-h), var(--color-primary-s), 90%); // Light
  --color-primary-300: hsl(var(--color-primary-h), var(--color-primary-s), 75%);
  --color-primary-400: hsl(var(--color-primary-h), var(--color-primary-s), 65%); // Base
  --color-primary-500: hsl(var(--color-primary-h), var(--color-primary-s), 55%);
  --color-primary-600: hsl(var(--color-primary-h), var(--color-primary-s), 42%); // Dark
  --color-primary-700: hsl(var(--color-primary-h), var(--color-primary-s), 35%);
  --color-primary-800: hsl(var(--color-primary-h), var(--color-primary-s), 25%); // Darker - "Upward Blue" official brand color
  --color-primary-900: hsl(var(--color-primary-h), var(--color-primary-s), 15%); // Darkest

  --color-secondary-100: hsl(var(--color-secondary-h), var(--color-secondary-s), 94%); // Lightest
  --color-secondary-200: hsl(var(--color-secondary-h), var(--color-secondary-s), 89%); // Lighter
  --color-secondary-300: hsl(var(--color-secondary-h), var(--color-secondary-s), 79%); // Light
  --color-secondary-400: hsl(var(--color-secondary-h), var(--color-secondary-s), 69%); // Base
  --color-secondary-500: hsl(var(--color-secondary-h), var(--color-secondary-s), 59%); // "Happiness" official brand color
  --color-secondary-600: hsl(var(--color-secondary-h), var(--color-secondary-s), 49%); // Dark
  --color-secondary-700: hsl(var(--color-secondary-h), var(--color-secondary-s), 39%);
  --color-secondary-800: hsl(var(--color-secondary-h), var(--color-secondary-s), 31%);
  --color-secondary-900: hsl(var(--color-secondary-h), var(--color-secondary-s), 23%); // Darkest

  --color-green-50:  hsl(var(--color-green-h), var(--color-green-s), 95%); // Lightest
  --color-green-100: hsl(var(--color-green-h), var(--color-green-s), 84%);
  --color-green-200: hsl(var(--color-green-h), var(--color-green-s), 74%); // Lighter
  --color-green-300: hsl(var(--color-green-h), var(--color-green-s), 64%); // Light
  --color-green-400: hsl(var(--color-green-h), var(--color-green-s), 54%); // Base
  --color-green-500: hsl(var(--color-green-h), var(--color-green-s), 43%); // Dark
  --color-green-600: hsl(var(--color-green-h), var(--color-green-s), 34%); // Darker
  --color-green-700: hsl(var(--color-green-h), var(--color-green-s), 24%);
  --color-green-800: hsl(var(--color-green-h), var(--color-green-s), 20%);
  --color-green-900: hsl(var(--color-green-h), var(--color-green-s), 14%); // Darkest

  --color-orange-50:  hsl(var(--color-orange-h), var(--color-orange-s), 97%); // Lightest
  --color-orange-100: hsl(var(--color-orange-h), var(--color-orange-s), 87%);
  --color-orange-200: hsl(var(--color-orange-h), var(--color-orange-s), 77%); // Lighter
  --color-orange-300: hsl(var(--color-orange-h), var(--color-orange-s), 67%); // Light
  --color-orange-400: hsl(var(--color-orange-h), var(--color-orange-s), 57%); // Base
  --color-orange-500: hsl(var(--color-orange-h), var(--color-orange-s), 45%); // Dark
  --color-orange-600: hsl(var(--color-orange-h), var(--color-orange-s), 37%); // Darker
  --color-orange-700: hsl(var(--color-orange-h), var(--color-orange-s), 27%);
  --color-orange-800: hsl(var(--color-orange-h), var(--color-orange-s), 17%);
  --color-orange-900: hsl(var(--color-orange-h), var(--color-orange-s), 13%); // Darkest

  --color-red-50:  hsl(var(--color-red-h), var(--color-red-s), 95%); // Lightest
  --color-red-100: hsl(var(--color-red-h), var(--color-red-s), 85%);
  --color-red-200: hsl(var(--color-red-h), var(--color-red-s), 75%); // Lighter
  --color-red-300: hsl(var(--color-red-h), var(--color-red-s), 65%); // Light
  --color-red-400: hsl(var(--color-red-h), var(--color-red-s), 55%); // Base
  --color-red-500: hsl(var(--color-red-h), var(--color-red-s), 45%); // Dark
  --color-red-600: hsl(var(--color-red-h), var(--color-red-s), 35%); // Darker
  --color-red-700: hsl(var(--color-red-h), var(--color-red-s), 25%);
  --color-red-800: hsl(var(--color-red-h), var(--color-red-s), 15%);
  --color-red-900: hsl(var(--color-red-h), var(--color-red-s), 10%); // Darkest

  // Normal usage color scales
  --color-neutral-lightest:   var(--color-neutral-50);
  --color-neutral-lighter:    var(--color-neutral-100);
  --color-neutral-light:      var(--color-neutral-200);
  --color-neutral-base:       var(--color-neutral-400);
  --color-neutral-dark:       var(--color-neutral-700);
  --color-neutral-darker:     var(--color-neutral-800);
  --color-neutral-darkest:    var(--color-neutral-900);

  --color-primary-lightest:   var(--color-primary-50);
  --color-primary-lighter:    var(--color-primary-100);
  --color-primary-light:      var(--color-primary-200);
  --color-primary-base:       var(--color-primary-400);
  --color-primary-dark:       var(--color-primary-600);
  --color-primary-darker:     var(--color-primary-800);
  --color-primary-darkest:    var(--color-primary-900);

  --color-secondary-lightest: var(--color-secondary-100);
  --color-secondary-lighter:  var(--color-secondary-200);
  --color-secondary-light:    var(--color-secondary-300);
  --color-secondary-base:     var(--color-secondary-400);
  --color-secondary-dark:     var(--color-secondary-600);
  --color-secondary-darker:   var(--color-secondary-800);
  --color-secondary-darkest:  var(--color-secondary-900);

  --color-success-lightest: var(--color-green-50);
  --color-success-lighter:  var(--color-green-200);
  --color-success-light:    var(--color-green-300);
  --color-success-base:     var(--color-green-400);
  --color-success-dark:     var(--color-green-500);
  --color-success-darker:   var(--color-green-600);
  --color-success-darkest:  var(--color-green-900);
  --color-success-text:     var(--color-neutral-darker);

  --color-info-lightest: var(--color-neutral-50);
  --color-info-lighter:  var(--color-neutral-200);
  --color-info-light:    var(--color-neutral-300);
  --color-info-base:     var(--color-neutral-400);
  --color-info-dark:     var(--color-neutral-500);
  --color-info-darker:   var(--color-neutral-600);
  --color-info-darkest:  var(--color-neutral-900);
  --color-info-text:     var(--color-neutral-darker);

  --color-warning-lightest: var(--color-orange-50);
  --color-warning-lighter:  var(--color-orange-200);
  --color-warning-light:    var(--color-orange-300);
  --color-warning-base:     var(--color-orange-400);
  --color-warning-dark:     var(--color-orange-500);
  --color-warning-darker:   var(--color-orange-600);
  --color-warning-darkest:  var(--color-orange-900);
  --color-warning-text:     var(--color-neutral-darker);

  --color-danger-lightest: var(--color-red-50);
  --color-danger-lighter:  var(--color-red-200);
  --color-danger-light:    var(--color-red-300);
  --color-danger-base:     var(--color-red-400);
  --color-danger-dark:     var(--color-red-500);
  --color-danger-darker:   var(--color-red-600);
  --color-danger-darkest:  var(--color-red-900);
  --color-danger-text:     var(--color-neutral-darker);

  // Miscellaneous color variables
  --neutral-layer-app-background: var(--color-neutral-100);
  --color-disabled: var(--color-neutral-base);
  --opacity-disabled-btn: 0.4;

  // TODO: Replace these colors with correct variables throughout the app
  --color-primary-text: var(--color-neutral-lightest);
  --color-secondary-text: var(--color-neutral-darkest);

  --neutral-layer-app-text: var(--color-black);

  --neutral-layer-1-background: var(--color-neutral-50);
  --neutral-layer-1-text: var(--color-neutral-800);
  --neutral-layer-1-border: var(--color-neutral-400);

  --neutral-layer-2-background: var(--color-neutral-300);
  --neutral-layer-2-text: var(--color-neutral-800);
  --neutral-layer-2-border: var(--color-neutral-500);

  --neutral-layer-3-background: var(--color-neutral-400);
  --neutral-layer-3-text: var(--color-neutral-800);
  --neutral-layer-3-border: var(--color-neutral-600);

  --neutral-layer-4-background: var(--color-neutral-500);
  --neutral-layer-4-text: var(--color-neutral-100);
  --neutral-layer-4-border: var(--color-neutral-200);

  --neutral-layer-card: var(--color-white);
  --neutral-layer-card-border: var(--color-neutral-200);
  --neutral-layer-card-container: var(--color-neutral-200);
  --neutral-layer-floating: var(--color-neutral-200);

  // Borders
  --radius-xs:  4px;
  --radius-sm:  6px;
  --radius-md:  10px;
  --radius:     12px;
  --radius-lg:  14px;
  --radius-xl:  16px;
  --radius-max: 50%;

  --border-width: 2px;
  --border-width-sm: 1px;

  // Transition speeds
  --transition-fast: all 150ms;
  --transition-slow: all 300ms;

  // Shadows
  --shadow-neutral-hsl: var(--color-neutral-h), var(--color-neutral-s), 16%;
  --shadow-neutral-elevation-sm:
    0 2px 2px hsla(var(--shadow-neutral-hsl), 0.03),
    0 2px 4px hsla(var(--shadow-neutral-hsl), 0.03),
    0 2px 8px hsla(var(--shadow-neutral-hsl), 0.03);
  --shadow-neutral-elevation-md:
    0 2px 2px hsla(var(--shadow-neutral-hsl), 0.01),
    0 2px 4px hsla(var(--shadow-neutral-hsl), 0.01),
    0 2px 8px hsla(var(--shadow-neutral-hsl), 0.01);

  // TODO: Replace this shadow with correct shadow
  --footer-shadow: 0 -8px 16px rgba(0, 0, 0, 0.08);

  // Font Families
  --font-primary:   'Inter', sans-serif;
  --font-icons:     'Material Icons';
  --font-signature: 'Satisfy', cursive;

  // Text sizes
  --text-base-size:   4px;

  --text-xs: calc(2 * var(--text-base-size)); // 8px
  --text-sm: calc(2.75 * var(--text-base-size)); // 11px
  --text-md: calc(3.5 * var(--text-base-size)); // 14px
  --text-lg: calc(4 * var(--text-base-size)); // 16px
  --text-xl: calc(6 * var(--text-base-size)); // 24px

  // Line heights
  --line-height-none:     0;
  --line-height-tightest: 1;
  --line-height-tighter:  1.15;
  --line-height-tight:    1.3;
  --line-height-base:     1.5;
  --line-height-loose:    1.6;
  --line-height-looser:   1.7;
  --line-height-loosest:  1.8;

  // Spacing
  --space-unit: 4px;

  --space-xxxxs: calc(1 * var(--space-unit)); // 4px
  --space-xxxs:  calc(1.5 * var(--space-unit)); // 6px
  --space-xxs:   calc(2 * var(--space-unit)); // 8px
  --space-xs:    calc(3 * var(--space-unit)); // 12px
  --space-sm:    calc(4 * var(--space-unit)); // 16px
  --space-md:    calc(5 * var(--space-unit)); // 20px
  --space-lg:    calc(6 * var(--space-unit)); // 24px
  --space-xl:    calc(8 * var(--space-unit)); // 32px
  --space-xxl:   calc(10 * var(--space-unit)); // 40px
  --space-xxxl:  calc(20 * var(--space-unit)); // 80px
  --space-xxxxl: calc(30 * var(--space-unit)); // 120px

  // Letter spacing
  --letter-spacing-text-xxs-small-caps: 0.12em;
  --letter-spacing-text-lg: -0.02em;

  // Icon sizing
  --icon-base-material: 1.3em;
  --icon-base-custom: 24px;
  --icon-max-width: 28px;

  // Input heights
  --mobile-tap-height: 44px;
  --input-height: 40px;

  // Flashes
  --flash-icon: 24px;
  --flash-transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
  --flash-width: 25rem;

  @media (max-width: $breakpoint-sm), (max-height: $breakpoint-sm) {
    --input-height: var(--mobile-tap-height);
  }

  --loading-spinner-width: 4rem;
}

/* stylelint-enable declaration-colon-space-after */
