.stats-section__cards {
  --gap: var(--space-sm);
  gap: var(--gap);
}

.stats-section__card {
  width: calc(50% - 0.5 * var(--gap));
}

@media (max-width: $breakpoint-sm) {
  .stats-section {
    --text-base-size: 2.5px;
  }

  .stats-section__cards {
    --gap: var(--space-xxxs);
  }

  .stats-section__card {
    padding: 0;
  }
}

.stats-section__title {
  border-bottom: 2px solid var(--color-neutral-light);
  margin-bottom: var(--gap);
}

.stats-section__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: var(--space-xxxs);
}

.stat {
  padding: var(--space-md) var(--space-lg);
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

a.stat {
  color: var(--color-black);
  text-decoration: none;
}

.stat + .stat {
  border-left: 2px solid var(--color-neutral-light);
}

.stat__quantity {
  font-size: calc(10 * var(--text-base-size));
  line-height: var(--text-xxl);
  margin-right: var(--space-md);
}

.stat__title {
  font-size: var(--text-md);
  color: var(--color-neutral-dark);
  white-space: nowrap;
  font-weight: 600;
}

.stat__value {
  color: var(--color-neutral-base);
}

.chart {
  position: relative;
  width: 100%;
  aspect-ratio: 2;
  --chart-scale: 16;
}

.news-items {
  gap: var(--space-xs);
  overflow-x: hidden;
}

.news-item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .trix-content a {
    word-break: break-all;
  }
}

.news-item__date {
  font-weight: bolder;
  word-break: keep-all;
}

.news-item--staff-only {
  border: 2px solid var(--color-warning-lighter);
  background-color: var(--color-secondary-lightest);
  border-radius: var(--radius-sm);
}

@media only screen and (max-width: $breakpoint-sm) {
  .page-header {
    padding-top: var(--space-xxs);
    padding-bottom: var(--space-xxs);
  }

  .container > .stats-section {
    padding-left: 0;
    padding-right: 0;
  }

  .chart {
    height: 250px;
    aspect-ratio: unset;
    --chart-scale: 16;
  }

  .stat {
    padding: var(--space-xxs) var(--space-xs);
    width: 50%;
  }

  .stat__quantity {
    margin-right: var(--space-xxxs);
  }

  .news-item {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: $breakpoint-xs) {
  .stats-section__cards--responsive {
    flex-direction: column;
    --text-base-size: 4px;

    .stats-section__card {
      width: 100%;
    }

    .stat {
      width: 100%;
    }
  }
}
