// Early Pay Off table styles

.epo-grid-three {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media only screen and (max-width: $breakpoint-sm) {
    grid-template-columns: auto;
  }
}

.epo-grid-two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  &:nth-child(2) {
    border-left: 2px var(--color-neutral-base) solid;
    border-right: 2px var(--color-neutral-base) solid;

    @media only screen and (max-width: $breakpoint-sm) {
      border: none;
    }
  }
}

.epo-cell {
  border: 1px var(--color-neutral-base) solid;
  padding: var(--space-sm);
}

@media only screen and (max-width: $breakpoint-sm) {
  .epo-hide-header {
    display: none;
  }
}

.epo-header {
  background: var(--color-neutral-light);
  border: 1px var(--color-neutral-base) solid;
  padding: var(--space-sm);
  min-height: 96px;
}
