//Color
.primary-dark {
  color: var(--color-primary-dark) !important;
}

.danger {
  color: var(--color-danger-base);
}

// Width

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.auto-width {
  width: auto;
}

.one-third-width {
  width: calc(100% / 3);
}

.two-thirds-width {
  width: calc(100% * 2 / 3);
}

.full-height {
  height: 100%;
}

// Flex Properties

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-gap-xxs {
  gap: var(--space-xxs);
}

.flex-gap-sm {
  gap: var(--space-sm);
}

.flex-gap-md {
  gap: var(--space-md);
}

.flex-gap-lg {
  gap: var(--space-lg);
}

.flex-gap-xl {
  gap: var(--space-xl);
}

.equal-row > * {
  flex: 1;
}

// Justify Content

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

// Align Items

.items-stretch {
  align-items: stretch;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

// Align Self

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-end {
  align-self: flex-end;
}

.self-stretch {
  align-self: stretch;
}

// Text Alignment

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bolder;
}

.text-italic {
  font-style: italic;
}

// Font size

.font-md {
  font-size: var(--text-md);
}

.font-sm {
  font-size: var(--text-sm);
}

.font-xs {
  font-size: var(--text-xs);
}

// Box Margin

.margin-xl {
  margin: var(--space-xl);
}

.margin-lg {
  margin: var(--space-lg);
}

.margin-md {
  margin: var(--space-md);
}

.margin-sm {
  margin: var(--space-sm);
}

.margin-xs {
  margin: var(--space-xs);
}

.margin-none {
  margin: 0;
}

// Vertical Margin

.margin-y-xl {
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
}

.margin-y-lg {
  margin-top: var(--space-lg);
  margin-bottom: var(--space-lg);
}

.margin-y-md {
  margin-top: var(--space-md);
  margin-bottom: var(--space-md);
}

.margin-y-sm {
  margin-top: var(--space-sm);
  margin-bottom: var(--space-sm);
}

.margin-y-xs {
  margin-top: var(--space-xs);
  margin-bottom: var(--space-xs);
}

.margin-y-none {
  margin-top: 0;
  margin-bottom: 0;
}

// Horizontal Margin

.margin-x-xl {
  margin-right: var(--space-xl);
  margin-left: var(--space-xl);
}

.margin-x-lg {
  margin-right: var(--space-lg);
  margin-left: var(--space-lg);
}

.margin-x-md {
  margin-right: var(--space-md);
  margin-left: var(--space-md);
}

.margin-x-sm {
  margin-right: var(--space-sm);
  margin-left: var(--space-sm);
}

.margin-x-xs {
  margin-right: var(--space-xs);
  margin-left: var(--space-xs);
}

.margin-x-none {
  margin-right: 0;
  margin-left: 0;
}

// Top Margin

.margin-top-xl {
  margin-top: var(--space-xl);
}

.margin-top-lg {
  margin-top: var(--space-lg);
}

.margin-top-md {
  margin-top: var(--space-md);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-none {
  margin-top: 0;
}

// Bottom Margin

.margin-bottom-xxl {
  margin-bottom: var(--space-xxl);
}

.margin-bottom-xl {
  margin-bottom: var(--space-xl);
}

.margin-bottom-lg {
  margin-bottom: var(--space-lg);
}

.margin-bottom-md {
  margin-bottom: var(--space-md);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-none {
  margin-bottom: 0;
}

// Right Margin

.margin-right-xl {
  margin-right: var(--space-xl);
}

.margin-right-lg {
  margin-right: var(--space-lg);
}

.margin-right-md {
  margin-right: var(--space-md);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-xs {
  margin-right: var(--space-xs);
}

.margin-right-xxs {
  margin-right: var(--space-xxs);
}

.margin-right-none {
  margin-right: 0;
}

// Left Margin

.margin-left-xl {
  margin-left: var(--space-xl);
}

.margin-left-lg {
  margin-left: var(--space-lg);
}

.margin-left-md {
  margin-left: var(--space-md);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-none {
  margin-left: 0;
}

.no-decoration {
  text-decoration: none;
}

// Resize

.resize-vertical {
  resize: vertical;
}

.resize-horizontal {
  resize: horizontal;
}

.resize-none {
  resize: none;
}

// Box Padding

.padding-xl {
  padding: var(--space-xl);
}

.padding-lg {
  padding: var(--space-lg);
}

.padding-md {
  padding: var(--space-md);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-none {
  padding: 0;
}

// Vertical Padding

.padding-y-xl {
  padding-top: var(--space-xl);
  padding-bottom: var(--space-xl);
}

.padding-y-lg {
  padding-top: var(--space-lg);
  padding-bottom: var(--space-lg);
}

.padding-y-md {
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);
}

.padding-y-sm {
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
}

.padding-y-xs {
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.padding-y-none {
  padding-top: 0;
  padding-bottom: 0;
}

// Horizontal Padding

.padding-x-xl {
  padding-right: var(--space-xl);
  padding-left: var(--space-xl);
}

.padding-x-lg {
  padding-right: var(--space-lg);
  padding-left: var(--space-lg);
}

.padding-x-md {
  padding-right: var(--space-md);
  padding-left: var(--space-md);
}

.padding-x-sm {
  padding-right: var(--space-sm);
  padding-left: var(--space-sm);
}

.padding-x-xs {
  padding-right: var(--space-xs);
  padding-left: var(--space-xs);
}

.padding-x-none {
  padding-right: 0;
  padding-left: 0;
}

// Top Padding

.padding-top-xl {
  padding-top: var(--space-xl);
}

.padding-top-lg {
  padding-top: var(--space-lg);
}

.padding-top-md {
  padding-top: var(--space-md);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-none {
  padding-top: 0;
}

// Bottom Padding

.padding-bottom-xxl {
  padding-bottom: var(--space-xxl);
}

.padding-bottom-xl {
  padding-bottom: var(--space-xl);
}

.padding-bottom-lg {
  padding-bottom: var(--space-lg);
}

.padding-bottom-md {
  padding-bottom: var(--space-md);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-none {
  padding-bottom: 0;
}

// Right Padding

.padding-right-xl {
  padding-right: var(--space-xl);
}

.padding-right-lg {
  padding-right: var(--space-lg);
}

.padding-right-md {
  padding-right: var(--space-md);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-none {
  padding-right: 0;
}

// Left Padding

.padding-left-xl {
  padding-left: var(--space-xl);
}

.padding-left-lg {
  padding-left: var(--space-lg);
}

.padding-left-md {
  padding-left: var(--space-md);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-none {
  padding-left: 0;
}

// Miscellaneous

.hidden {
  display: none !important;
}

.visibility-false {
  visibility: hidden;
}

.inline {
  display: inline-block;
}

.block {
  display: block;
}

.nowrap {
  white-space: nowrap;
}

// Hide at breakpoint

@media (max-width: $breakpoint-xs) {
  .hide-xs {
    display: none;
  }
}

@media (max-width: $breakpoint-sm) {
  .hide-sm {
    display: none;
  }
}

@media (max-width: $breakpoint-md) {
  .hide-md {
    display: none;
  }
}

@media (max-width: $breakpoint-lg) {
  .hide-lg {
    display: none;
  }
}

@media (max-width: $breakpoint-xl) {
  .hide-xl {
    display: none;
  }
}
