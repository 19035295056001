.page-header {
  background-color: var(--color-neutral-lightest);
  border-bottom: var(--border-width-sm) solid var(--color-neutral-light);
  padding-top: var(--space-md);
  padding-bottom: var(--space-md);

  @media (max-width: $breakpoint-md) {
    margin-top: var(--mobile-tap-height);
  }
}

.page-header__main {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.page-header__scope {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: var(--space-md);
}

.page-header__title {
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  gap: var(--space-xxs);
  color: var(--color-neutral-darker);
  font-size: var(--text-xl);
  letter-spacing: var(--letter-spacing-text-lg);
  margin: 0;

  @media (max-width: $breakpoint-md) {
    display: none;
  }
}

.page-header__actions {
  display: grid;
  grid-auto-flow: column;
  gap: var(--space-md);
  align-items: center;
}

.page-filters {
  margin-bottom: var(--space-xl);
}
