.worklist_actions {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xxxs) var(--space-sm);
}

.scope-switcher {
  display: flex;
  flex: 1;
  margin-left: var(--space-sm);
}

.scope-switcher__active {
  background-color: var(--color-neutral-light);
}

@media only screen and (max-width: $breakpoint-xs) {
  .scope-switcher {
    flex-direction: column;

    & > * {
      width: 100%;
    }

    .btn--group:first-child {
      border-radius: var(--radius-sm) var(--radius-sm) 0 0;
    }

    .btn--group + .btn--group {
      border-left-width: 1px;
      border-top-width: 0;
    }

    .btn--group:last-child {
      border-radius: 0 0 var(--radius-sm) var(--radius-sm);
    }
  }
}
