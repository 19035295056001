:root {
  --modal-width: 564px;
  --modal-transition-speed: 300ms;
}

.modal {
  visibility: hidden;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;

  /* Prevent Chrome on Windows from adding a focus outline. For details, see
     https://github.com/twbs/bootstrap/pull/10951. */
  outline: 0;

  /* We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
     gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
     See also https://github.com/twbs/bootstrap/issues/17695 */
}

// override for compatibility with the Clearent modal styles
.Clearent-modal.modal.fade.in {
  visibility: visible;
}

.Clearent:not(.inline) .Clearent-dialog.modal-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-height: unset;
  height: calc(100vh - 50px);
}

.modal__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  background: var(--color-black);
  visibility: hidden;
  opacity: 0;
  transition: all var(--modal-transition-speed);
}

.modal__content {
  max-height: 500px;
  width: var(--modal-width);
  padding: var(--space-lg);
  background: var(--color-white);
  border-radius: var(--radius);
  z-index: 12;
  transform: scale(0.7);
  opacity: 0;
  overflow-y: auto;
  transition: all var(--modal-transition-speed);

  .form__group {
    margin-left: 0;
    margin-right: var(--space-xs);
  }

  .form__label {
    white-space: nowrap;
  }
}

// TODO make responsive
.modal__content--large {
  max-height: 800px;
  width: 1100px;
}

.modal--active {
  display: flex;
  visibility: visible;

  .modal__content {
    transform: scale(1);
    opacity: 1;
  }

  .modal__backdrop {
    visibility: visible;
    opacity: 0.5;
  }
}

.modal__body {
  overflow-y: auto;
}

.modal__close {
  float: right;
  cursor: pointer;
}

.modal__content--extra-height {
  height: 200px;
}
