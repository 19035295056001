.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;

  z-index: 100;
  border: calc(var(--loading-spinner-width) / 5) solid var(--color-neutral-100);
  border-top: calc(var(--loading-spinner-width) / 5) solid var(--color-primary-500);
  border-radius: 50%;
  width: var(--loading-spinner-width);
  height: var(--loading-spinner-width);
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg) translate(-50%, -50%); }
  100% { transform: rotate(360deg) translate(-50%, -50%); }
}

.opaque {
  position: absolute;
  left: 0;
  top: 0;

  height: 100%;
  width: 100%;
  z-index: 10;

  background-color: var(--color-neutral-800);
  opacity: 50%;
}
