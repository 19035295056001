.photo-tray {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 200px));
  gap: var(--space-md);
  width: 100%;
  justify-content: flex-start;

  & > .btn--upload,
  & > .photo-tray__photo {
    aspect-ratio: 4/3;
    height: 100%;
  }
}

.photo-upload-modal {
  .photo-tray {
    overflow-x: scroll;
  }
}

.photo-tray__remove {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: var(--space-lg);
  height: var(--space-lg);
  background-color: var(--color-white);
  cursor: pointer;
}

.photo-tray__photo {
  display: flex;
  justify-content: center;
  align-items: center;

  transform: translate(0);

  aspect-ratio: 4/3;

  border: var(--border-width-sm) solid var(--color-neutral-light);

  background-color: var(--color-white);

  img {
    max-width: 8rem;
  }

  &:not(:hover) > .photo-tray__remove {
    display: none;
  }
}

.photo-tray__upload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  max-width: 8rem;

  border: 1px solid var(--color-neutral-600);
  margin: var(--space-sm);

  aspect-ratio: 4/3;

  background-color: var(--color-white);

  .material-icons {
    margin-bottom: var(--space-sm);
    font-size: var(--text-xl);
  }
}
