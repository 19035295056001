.comment__avatar {
  border: 1px solid var(--color-neutral-400);
  margin-right: var(--space-md);
}

.role-bubble {
  width: auto;
  height: 1.5em;
  border-radius: 20px;
  color: var(--color-white);
  display: flex;
  justify-content: center;
  background-color: var(--color-neutral-600);
  padding: var(--space-sm);
  align-items: center;
}

.comment__name {
  font-size: var(--text-md);
  font-weight: bold;
}

.comment__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-md);
}

.comment__section {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.comment {
  display: flex;
  margin-top: var(--space-md);
}

trix-toolbar .trix-button-row {
  flex-wrap: wrap;
}
