.swatch {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  width: 13.8rem;
  height: 7.5rem;
  margin-right: var(--space-md);
  padding: var(--space-md);

  border-radius: var(--radius);
  box-shadow: var(--shadow);

  span {
    padding: var(--space-xxs) var(--space-xs);

    color: var(--neutral-layer-2-text);
    border: 1px solid var(--neutral-layer-2-border);
    border-radius: var(--radius);
    background: var(--neutral-layer-2-background);
  }
}
