@font-face {
  font-family: "Inter";
  src: url("Inter-Regular.woff2") format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("Inter-Bold.woff2") format("woff2");
  font-weight: 700;
}
