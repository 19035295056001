@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import 'mixins/multiLineEllipsis';

.print__box {
  border: 1px solid black;
  padding: 1rem;
  max-width: 90vw;
}

.page dt {
  display: flex;
  margin-right: 0.5rem;
}

.page dd {
  flex-grow: 1;
  text-align: center;
  margin-right: 0.5rem;
}

.signature__font {
  font-family: var(--font-signature);
}

.signature-block {
  display: block;
  width: 100%;
  font-family: var(--font-signature);
  font-size: var(--text-xl);

  img {
    width: 15rem;
  }
}

.print__field {
  display: block;
  max-width: 90vw;
  border-bottom: 1px solid black;
}

.print__field--initial {
  display: inline-block;
  border-bottom: 1px solid black;
  font-family: var(--font-signature);
  padding: 0 0.75rem;
}

.print__field--signature {
  display: block;
  width: 100%;
  border-bottom: 1px solid black;
  font-family: var(--font-signature);
}

.print__field--signature img {
  height: 2rem;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}

.inline .print__field,
.inline .print__field--signature,
p .print__field,
p .print__field--signature {
  display: inline-block;
  width: auto;
}

.print__field--inline {
  display: inline-block;
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
  border-bottom: 1px solid black;
}

.page {
  max-width: 62rem;
  min-height: 79rem;
  position: relative;

  display: flex;
  flex-direction: column;

  // These are the older font and spacing settings used when the
  // PDF templates were developed. Possibly revisit this.
  --font-primary: 'Roboto', sans-serif;
  font-family: var(--font-primary);

  // Spacing
  --space-unit: 1rem;
  --space-xxs: calc(0.236 * var(--space-unit)); // 2px
  --space-xs: calc(0.382 * var(--space-unit)); // 6px
  --space-sm: calc(0.618 * var(--space-unit)); // 10px
  --space-md: calc(1 * var(--space-unit)); // 16px
  --space-lg: calc(1.618 * var(--space-unit)); // 26px
  --space-xl: calc(2.618 * var(--space-unit)); // 42px
  --space-xxl: calc(4.236 * var(--space-unit));
}

.page > ol > li {
  margin-top: 1rem;

  &.margin-top-sm {
    margin-top: var(--space-sm);
  }

  &.margin-top-none {
    margin-top: 0;
  }

  &.margin-top-xs {
    margin-top: var(--space-xs);
  }
}

.section__wrapper--y-marginless {
  div, header, dl {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.list--minimal-padding {
  padding-left: var(--space-md);
}

.page--fax, .page--fax p {
  font-size: 13pt;
}

.page--fax table {
  border: 3px solid var(--color-black);
}
.page--fax table tr {
  border-top: 0;
  border-bottom: 2px solid var(--color-black);
}

.page--fax table td {
  font-size: 13pt;
  padding: var(--space-xs);
}

.driver-instructions {
  width: calc(2 / 3 * 100%);
  display: flex;
  flex-direction: column;
  padding-right: var(--space-md);
}

.page__header {
  display: flex;
  flex-direction: row;
}

.page__header img {
  max-width: 100%;
  max-height: 6rem;
}

@media screen {
  body {
    height: inherit;
  }

  .print--simulation {
    background: #444;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .print--simulation .page {
    background: white;
    max-width: 62rem;
    padding: 3rem;
    margin: 1rem;
  }

  // technique from https://time2hack.com/triangles-and-arrow-heads-css/
  [data-action-needed]::before {
    content: ' ';
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    border: 1rem solid transparent;
    border-left-color: var(--color-danger-dark);
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-right: 0;
  }

  [data-action-needed]::after {
    content: ' ';
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    border: 1rem solid transparent;
    border-right-color: var(--color-danger-dark);
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left: 0;
  }
}

.responsive-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.flex-row-reverse {
    flex-direction: row-reverse;
  }
  &.items-start {
    align-items: flex-start;
  }
  &.items-end {
    align-items: flex-end;
  }
}

.responsive-row__block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.responsive-row__item {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-bottom: var(--space-xs);

  .responsive-row__item {
    margin-bottom: 0;
  }
}

.btn--signature {
  font-size: var(--text-md);
  padding: var(--space-xxs) var(--space-xs);
  white-space: nowrap;
}

p .btn--signature { // embedded signature
  padding-left: 2rem;
  padding-right: 2rem;
}

@media only screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
  .page {
    font-size: 16pt;
  }

  .print--simulation .page {
    padding: 2rem 1rem;
    margin: 1rem 0;
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .page {
    font-size: 16pt;
    width: 100%;
  }

  .responsive-row {
    flex-direction: column;
    align-items: flex-start;

    & > .responsive-row__item {
      width: 100%;
    }

    & > .responsive-row__block {
      width: 100%;
    }
  }

  .page__header {
    flex-direction: column;
  }

  .print--simulation .page {
    padding: 2rem 1rem;
    margin: 1rem 0;
  }

  .btn--signature {
    padding: var(--text-xs) var(--text-sm);
  }
}

@page {
  size: letter;
  // enshrining Chrome's defaults to hopefully prevent regressions
  margin: 0.39in;
}

@media print {
  html {
    font-size: 12px;
  }

  body {
    background: transparent;
  }

  li {
    margin-top: 1rem;
  }

  ol {
    ol > li {
      margin-top: 0;
    }
  }

  svg {
    overflow: visible;
  }

  .wrapper--main {
    padding-top: 0;
  }

  .page {
    // printable dimensions determined experimentally with Chrome 119 for MacOS
    width: 742px;
    height: 982px;
    max-width: unset;
    min-height: unset;
    page-break-after: always;
    overflow-y: hidden;
    overflow-x: overlay;
  }

  .signing {
    display: none;
  }

  .print__box, .print__field {
    max-width: 100vw;
  }
}

.signing {
  width: calc(100% - var(--sidebar-width));
  position: fixed;
  bottom: 0;
  background: var(--color-secondary-200);
  box-shadow: var(--footer-shadow);

  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}

.signing__tray {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 62rem;
  margin: 0 auto;
  padding: 0.25rem 1rem;
}

.signature__canvas {
  width: 100%;
  height: 6rem;
  border: 1px solid var(--color-neutral-400);
}

.tabular-figures {
  white-space: break-spaces;
}

@media screen and (max-width: $breakpoint-sm), (max-height: $breakpoint-sm) {
  .breadcrumbs ~ .print--simulation {
    padding-top: var(--space-xl);
  }

  .signing {
    z-index: 101;
  }
}

.itemization-table {
  height: 200px;
}

.disclaimer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  padding: 1rem;
}

.notes-container {
  display: flex;
  align-items: flex-start;
}

.diagram-container {
  width: 100%;
  height: 350px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.page#order-form {
  width: 100%;

  h1 {
    font-size: var(--text-xl);
  }

  .page__header img {
    max-width: 90%;
    max-height: 100px;
  }

  & * {
    font-size: 0.8rem;
  }

  .structure-diagram {
    max-height: 200px;
  }

  .notes {
    @include multiLineEllipsis($lineCount: 9);
  }

  table {
    tr th:not(:first-child), tr td:not(:first-child) {
      border-left: var(--border-width-sm) solid var(--color-neutral-base);
    }

    th, td {
      padding: var(--space-xxs) var(--space-xs);
    }
  }
}
