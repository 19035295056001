%table {
  position: relative;
  background-color: var(--color-white);
  font-size: var(--text-md);
  line-height: var(--line-height-base);
  overflow: hidden;
  text-align: left;
  border-spacing: 0;

  th, td {
    padding: var(--space-xs) var(--space-md);
  }
}

.table--borderless, .table--borderless tbody, .table--borderless tbody tr {
  border-width: 0;
}

.table--desktop {
  @extend %table;

  border: var(--card-border);
  border-radius: var(--radius);
  box-shadow: var(--shadow-neutral-elevation-md);
  color: var(--color-neutral-darker);

  .col--fit-content {
    width: 1%;
    white-space: nowrap;
  }

  th {
    background-color: var(--color-neutral-lighter);
    color: var(--color-primary-darker);
  }

  td {
    border-top: var(--card-border);

    .edit_lead { // Status select needs to take more room
      width: 7rem;
    }
  }

  @media (max-width: $breakpoint-sm) {
    display: none;
  }

  .row-icon {
    &:first-child {
      padding-right: 0;
    }

    &:last-child {
      padding-left: 0;
    }

    & > a {
      text-decoration: none;
    }
  }
}

.table--mobile {
  @extend %table;

  display: none;
  color: var(--color-neutral-darker);

  .td-mobile {
    padding: 0;
    border-bottom: var(--card-border);
  }

  .td-mobile__main {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;

    padding: var(--space-xs) var(--space-md);
    color: inherit;
    text-decoration: none;
  }

  .td-mobile__data {
    display: grid;
  }

  .td-mobile__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .td-mobile__actions {
    grid-column: 1/-1;
    padding: 0 var(--space-md) var(--space-md);
  }

  @media (max-width: $breakpoint-sm) {
    display: table;
  }
}

.table--compact {
  @extend %table;

  border: var(--border-width-sm) solid var(--color-black);

  th {
    color: var(--color-black);
  }

  tr:not(:first-child) th,
  tr:not(:first-child) td {
    border-top: var(--border-width-sm) solid var(--color-neutral-base);
  }

  .table__cell--fixed-width {
    max-width: 1em;
    white-space: nowrap;
  }
}

.table--inline {
  @extend .table--desktop;
  width: 100%;

  @media (max-width: $breakpoint-sm) {
    display: table;
    width: 100%;
  }
}

.tabular-input-align {
  text-align: right;
}

.key-value-pair {
  display: grid;
}

.data--key {
  color: var(--color-neutral-dark);
  font-size: var(--text-sm);
  font-weight: 700;
  letter-spacing: var(--letter-spacing-text-xxs-small-caps);
  text-transform: uppercase;
}

.table-row__inactive {
  background-color: var(--color-neutral-lighter);
  color: var(--color-neutral-dark);

  a {
    color: var(--color-neutral-dark);
  }
}
