button {
  background: none;
  border: 0;
  color: var(--color-black);
  cursor: pointer;
  margin: 0;
  padding: 0;
  outline: 0;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-width: var(--border-width-sm);
  border-radius: var(--radius-sm);
  border-style: solid;
  cursor: pointer;
  font-size: var(--text-md);
  height: var(--input-height);
  line-height: var(--line-height-tightest);
  padding-left: var(--space-sm);
  padding-right: var(--space-sm);
  text-decoration: none;
  -webkit-appearance: none;
  pointer-events: auto;
  width: min-content;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
    background-color: var(--color-disabled);
    border-color: var(--color-disabled);
    opacity: var(--opacity-disabled-btn);

    &:hover {
      background-color: var(--color-disabled);
      border-color: var(--color-disabled);
    }
  }

  @media (max-width: $breakpoint-sm) {
    height: var(--mobile-tap-height);
  }
}

.btn--group {
  border-radius: 0;
  cursor: default;
}

a.btn--group {
  cursor: pointer;
}

.btn--group:first-child {
  border-radius: var(--radius-sm) 0 0 var(--radius-sm);
}

.btn--group + .btn--group {
  border-left-width: 0;
  border-radius: 0;
}

.btn--group:last-child {
  border-radius: 0 var(--radius-sm) var(--radius-sm) 0;
}

.btn--primary {
  @extend .btn;
  background-color: var(--color-primary-darker);
  border-color: var(--color-primary-darker);
  color: var(--color-white);

  &:hover {
    background-color: var(--color-primary-darkest);
    border-color: var(--color-primary-darkest);
  }
  &:active {
    background-color: var(--color-primary-darkest);
    border-color: var(--color-primary-darkest);
  }
}

.btn--secondary {
  @extend .btn;
  background-color: var(--color-primary-lighter);
  border-color: var(--color-primary-dark);
  color: var(--color-primary-darker);

  &:hover {
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-darker);
  }
  &:active {
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-darker);
  }
}

.btn--destructive {
  @extend .btn;
  background-color: var(--color-white);
  border-color: var(--color-danger-dark);
  color: var(--color-danger-dark);

  &:hover {
    background-color: var(--color-danger-lightest);
    border-color: var(--color-danger-darker);

    &:active {
      background-color: var(--color-danger-lightest);
      border-color: var(--color-danger-darker);
    }
  }
}

.btn--upload {
  @extend .btn;
  display: grid;
  gap: var(--space-xxs);
  align-content: center;
  justify-items: center;
  border: var(--border-width-sm) solid var(--color-neutral-light);
  background-color: var(--color-neutral-lighter);
  color: var(--color-neutral-dark);
  height: 100%;
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
  margin: 0;

  &:hover {
    border-color: var(--color-neutral-dark);
  }
}

// Override for Clearent styling
.Clearent-pay.btn-primary.btn-lg {
  @extend .btn--primary;
}
