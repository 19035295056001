.delivery-receipt__upload {
  padding: var(--space-md);
  margin: var(--space-lg) var(--space-sm);
  border: 2px dashed var(--color-neutral-600);
}

.delivery-receipt__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0);
  width: 30%;
  margin-bottom: var(--space-md);

  img {
    max-width: 8rem;
  }
}

.separator__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator {
  border-top: 2px solid var(--color-neutral-600);
  width: 100%;
  height: 0;
  margin-left: var(--space-xs);
  margin-right: var(--space-xs);
}

.delivery-address__wrapper {
  display: flex;

  .form__dropdown {
    height: auto;
  }
}

.success-message__guest--container {
  text-align: center;
  padding: var(--space-xxl) var(--space-md);
}
