.breadcrumbs {
  display: inline-grid;
  gap: var(--space-xs);
  grid-auto-flow: column;
  align-items: center;
  justify-items: flex-start;
  font-size: var(--text-md);
  width: min-content;

  label.menu {
    display: none;
  }
}

.breadcrumb,
.breadcrumb a {
  color: var(--color-neutral-dark);
}

.breadcrumb,
.breadcrumb--current {
  white-space: nowrap;
}

.breadcrumb a {
  text-decoration: underline;
}

.breadcrumbs--responsive {
  display: none;
}

.breadcrumbs--responsive > .call__icon {
  margin-left: calc(var(--icon-max-width) * -1);
  margin-bottom: 0;
}

.breadcrumb::after {
  content: '>';
  padding-left: var(--space-xs);
}

.breadcrumbs ~ .container {
  margin-top: var(--space-md);
}

.breadcrumbs.breadcrumbs--responsive ~ .container {
  margin-top: var(--space-xl);
}

nav.tabbar {
  display: none;
}

@media only screen and (max-width: $breakpoint-md) {
  .breadcrumbs {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    display: grid;
    grid-template-columns: auto 1fr auto;

    height: var(--mobile-tap-height);

    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
    width: 100%;

    background-color: var(--color-primary-darker);
    box-shadow: inset 0 0 1px var(--color-primary-darkest);
    color: var(--color-primary-lightest);
    border-bottom: var(--space-xxs) solid var(--color-highlight);

    z-index: 100;

    label.menu {
      display: block;
      margin: 0;
    }
  }

  .breadcrumbs--responsive {
    display: flex;
  }

  .breadcrumbs + .container {
    margin-top: var(--space-xxl);
  }

  .breadcrumbs.breadcrumbs--responsive + .container {
    margin-top: var(--space-xxl);
  }

  .breadcrumbs.breadcrumbs--responsive + .flash {
    margin-top: 3.25rem;
  }

  .breadcrumbs + .flash + .container {
    margin-top: var(--space-md);
  }

  .breadcrumb {
    display: none;
  }

  .breadcrumb--back {
    display: block;
    width: 1em;
    height: 1.2em;
    a {
      visibility: hidden;
    }

    a::before {
      /* stylelint-disable font-family-no-missing-generic-family-keyword */
      font-family: 'Material Icons';
      /* stylelint-enable font-family-no-missing-generic-family-keyword */
      content: '\E5E0';
      font-size: 120%;
      visibility: visible;
      color: var(--color-primary-lightest);
    }
  }
  .breadcrumb--back::after {
    content: '';
  }

  .breadcrumb--current {
    width: 100%;
    text-align: center;
  }

  nav.tabbar {
    position: absolute;
    display: flex;
    width: 100%;
    background-color: var(--color-primary-darker);

    bottom: 0;

    z-index: 100;

    .sidebar-nav__item {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;

      flex-direction: column;
      font-size: var(--text-sm);
      font-weight: 400;
      padding: var(--space-xs) var(--space-xs) var(--space-sm) var(--space-xs);
      border-radius: 0;
      margin-bottom: 0;
    }

    .sidebar-nav__item--active {
      background-color: var(--color-primary-lightest);
    }

    .material-icons {
      margin-right: 0;
      margin-bottom: var(--space-xs);
    }

    label {
      display: flex;
      margin-right: 0;
    }
  }

  .container {
    width: 100%;
    overflow-x: hidden;
  }

  html, body {
    position: relative;
    overflow-x: hidden;
  }
  html {
    width: 100vw;
  }
}

.collapse {
  visibility: collapse;
}

.call__icon {
  color: var(--color-primary-lightest);
  text-decoration: none;
  margin-bottom: -4px;
}
