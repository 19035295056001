:root {
  --sidebar-width: 275px;
  --sidebar-hover-color: var(--color-primary-darker);
  --sidebar-hover-active-color: rgba(252, 252, 252, 0.85);
}

.sidebar {
  display: flex;
  overflow: auto;
  flex: 0 0 var(--sidebar-width);
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding: var(--space-md);
  background: var(--color-primary-darker);
  box-shadow: var(--shadow-neutral-elevation-md);
}

.sidebar--right {
  flex-direction: column;
  width: calc(100% / 3);
  border-left: 1px solid var(--color-neutral-500);
  padding: 0 var(--space-md);
  margin-left: var(--space-md);
}

@media only screen and (max-device-width: $breakpoint-md) {
  .sidebar--right {
    width: 100%;
    margin-left: 0;
    border-left: 0;
    padding: 0 0;
  }
}

.sidebar_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: var(--color-primary-lightest);
  }
}

.sidebar__role {
  color: var(--color-white);
  font-size: var(--text-md);
  display: flex;
  align-items: center;
  gap: var(--space-xxs);
  flex-wrap: wrap;
}

.role-badge {
  display: inline-block;
  background-color: var(--color-highlight);
  padding: 4px 8px;
  border-radius: 12px;
  color: var(--color-highlight-text);
}

.sidebar-nav_wrapper {
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  width: 100%;
  margin: var(--space-xl) 0 var(--space-lg) 0;
}

.sidebar-nav__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}

.sidebar-nav__item {
  display: flex;
  line-height: 0;
  font-weight: 500;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: var(--space-xxs);
  list-style: none;
  padding: var(--space-xs);
  border-radius: var(--radius);
  text-decoration: none;
  color: var(--color-primary-lightest);
  transition: var(--transition-fast);
  white-space: nowrap;

  .material-icons {
    width: 24px;
    height: 24px;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--space-sm);
  }

  @media (max-width: $breakpoint-xs) {
    padding: var(--space-md);
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    background: var(--sidebar-hover-color);
  }

  &:active {
    opacity: 0.7;
  }
}

.sidebar-nav__item--active {
  color: var(--color-primary-darker);
  background: var(--color-white);

  &:hover {
    background: var(--sidebar-hover-active-color);
  }
}

.sidebar__icon {
  display: none;
  color: var(--color-black);

  @media (max-width: $breakpoint-sm) {
    display: flex;
    z-index: 102;
    padding: var(--space-sm);
  }
}

.sidebar__icon--close {
  display: none;
  position: relative;
  color: var(--color-white);
  margin: 4px 0 0;
}

.sidebar__input {
  display: none;
}

// Makes sidebar collapsible
@media (max-width: $breakpoint-md) {
  .sidebar {
    position: absolute;
  }

  nav[role="off-canvas"] {
    position: absolute;
    width: 100%;
    right: -100%;

    z-index: 104;
    transition: var(--transition-slow);

    background-color: var(--color-primary-light-bland);
    background-color: var(--color-primary-darker);

    .sidebar_header > h2, .sidebar-nav__item {
      color: var(--color-primary-lightest);
    }

    .sidebar-nav__item--active {
      color: var(--color-primary-darker);
    }

    .sidebar__icon--close {
      color: var(--color-primary-lightest);
    }
  }

  .sidebar__input {
    &:checked ~ nav[role="off-canvas"] {

      .sidebar__icon {
        display: none;
      }

      .sidebar__icon--close {
        display: block;
      }

      .sidebar__icon--open {
        display: none;
      }

      right: 0;
    }
  }

  .sidebar-nav__list > .sidebar-nav__item {
    display: none;
  }

  .sidebar-nav__list > .sidebar-nav__item.sidebar-nav__item--more {
    display: flex;
  }
}
